<template>
    <div class="vueperslides__track-inner">
        <div class="vueperslide cursor-pointer" @click="sendEmit()">
            <img loading=lazy :src="joinPaths(productContent.photoPath)" alt="">
            <div class="d-block vueperslide__content-wrapper position-relative">
                <div class="vueperslide__title">
                    <h5 class="primary-color fw-bold" v-html="processedName"></h5>
                </div>
                <div v-if="showYear" class="vueperslide__content mt-3">
                    <h5>{{productContent.year}}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";

export default {
    props: {
        productContent: {
            type: Object
        },
        showYear: {
            type: Boolean
        }
    },
    methods: {
        joinPaths(relativePath){
            return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
        },
        sendEmit(){
            this.$emit('click')
        },
    },
    computed:
    {
        processedName(){
            const prefix = "Cooperativa de Freixo de Numão";
            const reg = "(\".*\")";

            var result = this.productContent ? this.productContent.name : '';
            result = result.replace(prefix, prefix + "<br>");
            result = result.replace(new RegExp(reg), "$1<br>");

            return result;
        }
    }
}
</script>

<style scoped>
.vueperslide img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.vueperslide__content{
    color: black;
}
@media screen and (max-width: 767px) {
.vueperslide img{
        height: 75%;
    }
}
</style>