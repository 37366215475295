export default class InputGetListProducts
{
  constructor(pageSize, pageNumber, orderBy, propertyName, searchText, visible, type) 
  {
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.orderBy = orderBy;
    this.propertyName = propertyName;
    this.searchText = searchText;
    this.visible = visible;
    this.type = type;
  }    
}